<template>
  <div v-if="!isFaqEmpty" class="bg-surface-default-low">
    <div class="px-24 py-56 md:mx-auto md:max-w-[720px]">
      <BlockTitle :subtitle="subtitle" :title="title" />

      <template v-if="showQuestions">
        <FaqContentItem
          v-for="question of questions"
          :key="question.id"
          v-bind="question"
        />
      </template>

      <div v-if="hasLink" class="flex flex-col md:items-center">
        <CTALink
          :cta-link="ctaLink"
          full-width="responsive"
          :tracking="trackingData"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useHead } from '#imports'
import { computed, reactive } from 'vue'

import {
  type FaqApiProps,
  STRUCTURED_DATA_TYPE,
} from '@backmarket/http-api/src/api-specs-content/models/faq'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import CTALink from '../../shared-components/CTALink/CTALink.vue'

import FaqContentItem from './FaqContentItem.vue'
import { buildFaqHead } from './helpers/build-head'

export type FaqProps = FaqApiProps & ContentBlockProps

const props = withDefaults(defineProps<FaqProps>(), {
  tracking: () => reactive({}),
  questions: () => reactive([]),
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

useHead(
  buildFaqHead({ questions: props.questions, rendering: props.rendering }),
)

const trackingData = computed(() => ({
  ...props.tracking,
  title: props.title,
  name: props.ctaLink?.label || '',
}))

const showQuestions = computed(
  () =>
    !isEmpty(props.questions) &&
    props.rendering !== STRUCTURED_DATA_TYPE.JSON_ONLY,
)

const hasLink = computed(() => !isEmpty(props.ctaLink))
const isFaqEmpty = computed(() => isEmpty(props.questions) && !hasLink.value)
</script>
