import type { CTALink } from './cta-link'
import type { Image } from './image'
import type { RichText } from './rich-text'

export const STRUCTURED_DATA_TYPE = {
  JSON_ONLY: 'JSON_ONLY',
  HTML_ONLY: 'HTML_ONLY',
  JSON_AND_HTML: 'JSON_AND_HTML',
} as const

export type StructuredDataType =
  (typeof STRUCTURED_DATA_TYPE)[keyof typeof STRUCTURED_DATA_TYPE]

type FaqQuestionImage = Omit<Image, 'widthMobile' | 'heightMobile'>

export type FaqQuestion = {
  id: string
  title: string
  text: RichText
  image?: FaqQuestionImage
}
export type FaqApiProps = {
  title: string
  subtitle: string | null
  questions: FaqQuestion[]
  rendering?: StructuredDataType
  ctaLink?: CTALink
}
